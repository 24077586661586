import axios from 'axios';
import { auth } from './firebase';
import envConsts from '../common/envConsts';

const defaultOptions = {
  baseURL: envConsts.VITE_BASE_API,
};

const instance = axios.create(defaultOptions);

let userInstance: any;

// Create a function to safely get the token
async function getToken() {
  if (!userInstance) {
    return ''; // Return an empty string or handle as needed
  }
  return await userInstance.getIdToken();
}

instance.interceptors.request.use(async req => {
  const token = await getToken(); // Use the safe getToken function
  if (token) {
    // Only set the Authorization header if token is not empty
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    req.headers['Authorization'] = `Bearer ${token}`;
  }
  return req;
});

auth.onAuthStateChanged(async user => {
  if (user) {
    userInstance = user;
    const token = await user.getIdToken();
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    userInstance = null; // Explicitly set to null if no user
    instance.defaults.headers.common['Authorization'] = '';
  }
});

export default instance;
