import React from 'react';
import PublicFooter from '../components/PublicFooter';
import PublicHeader from '../components/PublicHeader';
import styles from './PublicLayout.module.scss'

interface PublicLayoutProps {
  children: React.ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <div className={styles.publicLayout}>
      <PublicHeader />
        {children}
      <PublicFooter />
    </div>
  );
};

export default PublicLayout;
