import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface messageState {
  message?: {
    type: string;
    content: string;
  } | null;
}

const initialState: messageState = {
  message: null,
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    messageAction: (state, action: PayloadAction<{ type: string; content: string }>) => {
      state.message = action.payload;
    },
    messageClear: (state) => {
      state.message = null;
    },
  },
});

export const { messageAction, messageClear } = messageSlice.actions;

export default messageSlice.reducer;
