import { Link } from "react-router-dom";
import PublicLayout from "../../common/PublicLayout"
import styles from './Login.module.scss';

function SuccessfulSignUp() {
  return (
    <PublicLayout>
      <div className={styles.loginPage}>
        <div className={styles.loginWrapper}>
          <div className={styles.loginBody}>
            <div className={styles.loginHeading}>
              <h1>Welcome Aboard!</h1>
            </div>
            <div className={styles.loginSignup}>
              <p className={styles.successfulMessage}>
                Your account has been successfully created! We've sent you a confirmation email. Please verify your email to complete the registration process.
              </p>
            </div>
            <div className={styles.loginSignup}>
              <Link className={styles.loginLink} to="/login">
                Return to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  )
}

export default SuccessfulSignUp