import React from 'react';
import styles from './PublicHeader.module.scss';
import { Link } from 'react-router-dom';
import { APP_NAME } from '../../../constants';

const PublicHeader: React.FC = () => {
  return (
    <header className={styles.publicHeader}>
      <div className={styles.publicHeaderLogo}>
        <Link to="/">
          <img src="/fuse-logo.svg" alt={APP_NAME} />
        </Link>
      </div>
    </header>
  );
};

export default PublicHeader;
