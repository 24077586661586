import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_NAME } from '../../../constants';

interface ConnectionListenerProps {
  children: React.ReactElement;
}

const ConnectionListener: React.FC<ConnectionListenerProps> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    if (!navigator.onLine) {
      alert(
        `Uh oh! Looks like you are offline, ${APP_NAME} requires an internet connection, please check your connection and try again!`,
      );
    }
  }, [location]);

  return children;
};

export default ConnectionListener;
