// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  logEvent as gaLogEvent,
  setUserProperties,
  setUserId,
  EventParams,
  Analytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { AUTH_METHOD_PROVIDERS } from '../constants';
import envConsts from '../common/envConsts';

const firebaseConfig = {
  apiKey: envConsts.VITE_FIREBASE_API_KEY,
  authDomain: envConsts.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: envConsts.VITE_FIREBASE_PROJECT,
  storageBucket: envConsts.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: envConsts.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: envConsts.VITE_FIREBASE_WEB_APP_ID,
  measurementId: envConsts.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
let analytics: Analytics;
if (import.meta.env.VITE_ENV === 'production') {
  analytics = getAnalytics(app);
} else {
  analytics = getAnalytics(app);
  setAnalyticsCollectionEnabled(analytics, false);
}

// Initialize Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);
const facebook = new FacebookAuthProvider();
const google = new GoogleAuthProvider();

facebook.addScope('email');
facebook.addScope('public_profile');

const authProviders = {
  [AUTH_METHOD_PROVIDERS.FACEBOOK]: facebook,
  [AUTH_METHOD_PROVIDERS.GOOGLE]: google,
};

const logEvent = (eventName: string, eventParams?: EventParams) => {
  gaLogEvent(analytics, eventName, eventParams);
};

onAuthStateChanged(auth, user => {
  if (user) {
    setUserId(analytics, user.uid);
    setUserProperties(analytics, { email: user.email });
  } else {
    setUserId(analytics, null);
    setUserProperties(analytics, {});
  }
});

// Export the Firebase services for use in your application
export { app, auth, firestore, analytics, logEvent, setUserProperties, setUserId, authProviders };
