import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../../features/user/useAuth';
import PageLoader from '../PageLoader';
import { AUTH_METHOD_PROVIDERS } from '../../../constants';

interface MessageProps {
  children: React.ReactElement;
}

const AuthListener: React.FC<MessageProps> = ({ children }) => {
  const { user, logout, setIsLoading, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      setIsLoading(false);
      const { emailVerified, uid, providerData } = user;

      const hasVerifiedProvider = providerData.find(
        provider =>
          provider.providerId.split('.')[0] === AUTH_METHOD_PROVIDERS.GOOGLE ||
          provider.providerId.split('.')[0] === AUTH_METHOD_PROVIDERS.FACEBOOK,
      );

      if (!emailVerified && !hasVerifiedProvider) {
        logout();
        return;
      }
      if (location.pathname.includes('setup') || location.pathname.includes('payment-info')) {
        return;
      }
      if (uid && location.pathname.includes('login')) {
        const { from } = location.state || { from: { pathname: '/' } };
        navigate(from);
      }
    }
  }, [navigate, logout, user, location.state, location.pathname, setIsLoading]);

  if (isLoading) {
    return <PageLoader loading />;
  }

  return children;
};

export default AuthListener;
