import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './PageLoader.module.scss';

interface PageLoaderProps {
  loading?: boolean;
}

const PageLoader: React.FC<PageLoaderProps> = ({ loading }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition in={loading} timeout={500} classNames="fade" nodeRef={nodeRef} unmountOnExit>
      <div className={styles.pageLoader} ref={nodeRef}>
        <img className={styles.pageLoaderLogo} src="/icon.svg" alt="Fuse" />
      </div>
    </CSSTransition>
  );
};

export default PageLoader;
