import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { store } from './app/store.ts';
import { Provider } from 'react-redux';
import App from './App.tsx';
import './index.scss';

const stripePromise = loadStripe(`${import.meta.env.VITE_STRIPE_KEY}`);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito&display=swap',
          },
        ],
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Elements>
  </React.StrictMode>,
);
