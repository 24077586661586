import React from 'react';
import { QueryClientProvider } from 'react-query';
import AuthListener from './common/components/AuthListener/index.ts';
import queryClient from './app/queryClient.ts';
import { ToastContainer } from 'react-toastify';
import Routes from './Routes.tsx';
import 'react-toastify/dist/ReactToastify.css';
import ConnectionListener from './common/components/ConnectionListener';
import './App.scss';

function App() {
  const iOS = !!navigator.platform && /iPhone/.test(navigator.platform);
  if (iOS) {
    const meta = document?.querySelector<HTMLElement>('link[rel="manifest"]');

    if (meta) {
      meta.setAttribute('rel', 'no-on-ios');
    }
  }

  return (
    <div className="App">
      <ConnectionListener>
        <QueryClientProvider client={queryClient}>
          <AuthListener>
            <React.Suspense fallback={<></>}>
              <Routes />
            </React.Suspense>
          </AuthListener>
        </QueryClientProvider>
      </ConnectionListener>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
