import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { auth, logEvent } from '../../app/firebase';
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  onIdTokenChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginSuccess, logoutSuccess } from './userSlice';
import { messageAction, messageClear } from '../message/messageSlice';
import {
  AUTH_METHOD_PROVIDERS,
  MESSAGE_CHANGE_PASSWORD_FAILURE,
  MESSAGE_CHANGE_PASSWORD_SUCCESS,
  MESSAGE_EMAIL_UNVERIFIED,
  MESSAGE_ERROR,
  MESSAGE_FORGOT_FAILURE,
  MESSAGE_FORGOT_SUCCESS,
  MESSAGE_INVALID_LOGIN,
  MESSAGE_REGISTER_FAILURE,
  MESSAGE_SUCCESS,
  TRACKING_EVENTS,
} from '../../constants';
import axios from '../../app/axios';
import queryClient from '../../app/queryClient';
import type { AuthProvider, User } from 'firebase/auth';
import envConsts from '../../common/envConsts';

function useAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = auth.currentUser;

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, (user: User | null) => {
      if (user) {
        user?.getIdToken().then((token: string) => {
          setAuthToken(token);
          if (!user) {
            setIsLoading(false);
            return;
          }
          const { uid: id, emailVerified } = user;
          dispatch(loginSuccess({ id, emailVerified }));
        });
      } else {
        setIsLoading(false);
        dispatch(logoutSuccess());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  const login = async (userObj: any) => {
    const { email, password } = userObj;
    const { from } = location.state || { from: { pathname: '/' } };
    const res = await signInWithEmailAndPassword(auth, email, password).catch(() => {
      dispatch(messageAction({ type: MESSAGE_ERROR, content: MESSAGE_INVALID_LOGIN }));
    });
    if (!res) {
      return;
    }
    const { user } = res;
    if (!user?.emailVerified) {
      await auth.signOut();
      dispatch(
        messageAction({
          type: MESSAGE_ERROR,
          content: MESSAGE_EMAIL_UNVERIFIED,
        }),
      );
      return;
    }
    const response = {
      id: user.uid,
      emailVerified: user.emailVerified,
      provider: AUTH_METHOD_PROVIDERS.PASSWORD,
    };
    logEvent(TRACKING_EVENTS.LOGIN, { method: 'email' });
    dispatch(loginSuccess(response));
    dispatch(messageClear());
    navigate(from, { replace: true });
  };

  const loginWithProvider = async (authProvider: AuthProvider) => {
    let signUp = false;
    try {
      const { from } = location.state || { from: { pathname: '/' } };
      const res = await signInWithPopup(auth, authProvider).catch(e => {
        console.log(e);
      });

      if (!res) return;

      const { user } = res;
      const provider = authProvider.providerId.split('.')[0];

      const registeredUser = await axios.get('/users/me').catch(error => ({ error }));

      if ('error' in registeredUser && registeredUser.error?.response?.status === 404) {
        signUp = true;
        await axios.post('/users', { userId: user.uid, email: user.email ?? '', code: '' });
        logEvent(TRACKING_EVENTS.REGISTER, { method: provider });
      }

      logEvent(TRACKING_EVENTS.LOGIN, { method: provider });
      dispatch(loginSuccess({ id: user.uid, emailVerified: user.emailVerified, provider }));
      dispatch(messageClear());
      navigate(from, { replace: true });
    } catch (e) {
      if (signUp) {
        dispatch(
          messageAction({
            type: MESSAGE_ERROR,
            content: MESSAGE_REGISTER_FAILURE,
          }),
        );
        logEvent(TRACKING_EVENTS.REGISTER_FAIL);
      }
    }
  };

  const signup = async (userObj: any) => {
    const { email, password, displayName, code } = userObj;
    dispatch(messageClear());
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (user) {
        const currentUser = auth.currentUser;
        await signOut(auth)
        if (currentUser) {
          logEvent(TRACKING_EVENTS.REGISTER, { method: 'email' });
          await updateProfile(currentUser, { displayName });
          await sendEmailVerification(currentUser, {
            url: `${envConsts.VITE_BASE_URL}/login`,
          });
        }
        const { uid: userId } = user;
        await axios.post('/users', { userId, email, code });
      }
      navigate('/success_signup');
    } catch (e) {
      console.log(e);
      dispatch(
        messageAction({
          type: MESSAGE_ERROR,
          content: MESSAGE_REGISTER_FAILURE,
        }),
      );
      logEvent(TRACKING_EVENTS.REGISTER_FAIL);
    }
  };

  const changePassword = async (userObj: any) => {
    const { currentPassword, newPassword } = userObj;
    const user = auth.currentUser;
    try {
      if (user?.email != null) {
        const credential = EmailAuthProvider.credential(user?.email, currentPassword);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        logEvent(TRACKING_EVENTS.PASSWORD_CHANGE);
      }
      dispatch(
        messageAction({
          type: MESSAGE_SUCCESS,
          content: MESSAGE_CHANGE_PASSWORD_SUCCESS,
        }),
      );
    } catch (e) {
      logEvent(TRACKING_EVENTS.PASSWORD_CHANGE_FAIL);
      dispatch(
        messageAction({
          type: MESSAGE_ERROR,
          content: MESSAGE_CHANGE_PASSWORD_FAILURE,
        }),
      );
    }
  };

  const forgot = async (userObj: any) => {
    const { email } = userObj;
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(
          messageAction({
            type: MESSAGE_SUCCESS,
            content: MESSAGE_FORGOT_SUCCESS,
          }),
        );
      })
      .catch(() => {
        dispatch(
          messageAction({
            type: MESSAGE_ERROR,
            content: MESSAGE_FORGOT_FAILURE,
          }),
        );
      });
  };

  const logout = useCallback(async () => {
    await signOut(auth);
    logEvent(TRACKING_EVENTS.LOGOUT);
    dispatch(logoutSuccess());
    queryClient.clear();
    navigate('/login');
  }, [dispatch, navigate]);

  return {
    user,
    isLoading,
    setIsLoading,
    authToken,
    login,
    loginWithProvider,
    signup,
    changePassword,
    logout,
    forgot,
  };
}

export default useAuth;
