import React from 'react';
import styles from './PublicFooter.module.scss';
import { Link } from 'react-router-dom';

interface PublicFooterProps {}

const PublicFooter: React.FC<PublicFooterProps> = () => {
  return (
    <footer className={styles.publicFooter}>
      <div className={styles.publicFooterLinks}>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-and-conditions">Terms of Service</Link>
      </div>
    </footer>
  );
};

export default PublicFooter;
